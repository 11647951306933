/* make the customizations */
$theme-colors: (
  "primary": #1890ff,
  "secondary": #b37feb,
  "third": #001529,
  "success": #369705,
  "info": #0d6efd,
  "warning": #eccd01,
  "danger": #f5222d,
  "custom-light": #f5f5f5,
  "custom-black": #000000,
  "custom-dark": #141414,
  "custom-white": #ffffff,
);

$breadcrumb-divider: quote("/");

[data-bs-theme="dark"] {
  .bg-custom-black {
    background-color: #ffffff !important;
  }
  .bg-custom-dark {
    background-color: #f5f5f5 !important;
  }
  .bg-custom-light {
    background-color: #2b2d3e !important;
  }
  .bg-custom-white {
    background-color: #001529 !important;
  }
  color: #ffffff;
  td {
    color: white !important;
  }
  th {
    color: white !important;
  }
}

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
