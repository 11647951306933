.exitPart {
  .doorOpen {
    display: none;
  }
  &:hover {
    .doorOpen {
      display: block;
    }
    .doorClose {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .sidebar {
    transition: all 1.5s cubic-bezier(0, 0, 0, 0);
    position: fixed !important;
    z-index: 9;
    left: -15rem;
    width: 15rem;
  }
  .sidebarCheck:checked ~ .sidebar {
    transition: all 1.5s cubic-bezier(0, 0, 0, 0);
    left: 0rem;
    span {
      animation: sidebar-text-sm 3s forwards alternate;
      white-space: nowrap;
    }

    .userImageSiteLayout {
      margin-bottom: 12px;
    }
  }
}

@keyframes sidebar-text-sm {
  0% {
    opacity: 0%;
  }
  100% {
    opacity: 100%;
  }
}

.sidebar {
  overflow-x: hidden;
  width: 15rem;
}

@media screen and (min-width: 768px) {
  .sidebarCheck:checked ~ .sidebar {
    animation: sidebar-anim 3s forwards alternate;
    span {
      animation: sidebar-text 3s forwards alternate;
      white-space: nowrap;
    }
    .userNameSiteLayout {
      display: none;
    }
    .userImageSiteLayout {
      margin-bottom: 12px;
    }
  }
}

@keyframes sidebar-anim {
  0% {
    width: 15rem;
  }
  100% {
    width: 3.4rem;
  }
}

@keyframes sidebar-text {
  0% {
    opacity: 100%;
  }
  100% {
    opacity: 0;
  }
}

.dropdown-menu.show {
  position: fixed !important;
}
