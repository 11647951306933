.info-edit-part {
  display: none;
  &:hover {
    display: flex;
    flex-direction: column;
  }
  a {
    &:hover {
      background-color: rgba(241, 241, 241, 0.226);
    }
  }
}

.info-dot-icon {
  cursor: pointer;
}

.info-dot-icon:hover ~ .info-edit-part {
  display: flex;
  flex-direction: column;
}
