.adminSearchBox {
  display: none !important;
}
#showAdminSearchInput {
  display: none !important;
  &:checked ~ .adminSearchBox {
    display: flex !important;
    flex-direction: row;
  }
}

.page-item:first-child .page-link {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
