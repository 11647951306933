// .spinner-box {
//   width: 300px;
//   height: 300px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: transparent;
//   z-index: 10;
// }

// .configure-border-1 {
//   width: 115px;
//   height: 115px;
//   padding: 3px;
//   position: absolute;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: #fb5b53;
//   animation: configure-clockwise 3s ease-in-out 0s infinite alternate;
// }

// .configure-border-2 {
//   width: 115px;
//   height: 115px;
//   padding: 3px;
//   left: -115px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: rgb(63, 249, 220);
//   transform: rotate(45deg);
//   animation: configure-xclockwise 3s ease-in-out 0s infinite alternate;
// }

// .configure-core {
//   width: 100%;
//   height: 100%;
//   background-color: #1d2630;
// }

// @keyframes configure-clockwise {
//   0% {
//     transform: rotate(0);
//   }
//   25% {
//     transform: rotate(90deg);
//   }
//   50% {
//     transform: rotate(180deg);
//   }
//   75% {
//     transform: rotate(270deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// }

// @keyframes configure-xclockwise {
//   0% {
//     transform: rotate(45deg);
//   }
//   25% {
//     transform: rotate(-45deg);
//   }
//   50% {
//     transform: rotate(-135deg);
//   }
//   75% {
//     transform: rotate(-225deg);
//   }
//   100% {
//     transform: rotate(-315deg);
//   }
// }

.loading-wrapper {
  width: 200px;
  height: 60px;
  position: relative;
  z-index: 1;
}

.loading-circle {
  width: 20px;
  height: 20px;
  position: absolute;
  border-radius: 50%;
  // background-color: #fff;
  left: 15%;
  transform-origin: 50%;
  animation: circle7124 0.5s alternate infinite ease;
}

@keyframes circle7124 {
  0% {
    top: 60px;
    height: 5px;
    border-radius: 50px 50px 25px 25px;
    transform: scaleX(1.7);
  }

  40% {
    height: 20px;
    border-radius: 50%;
    transform: scaleX(1);
  }

  100% {
    top: 0%;
  }
}

.loading-circle:nth-child(2) {
  left: 45%;
  animation-delay: 0.2s;
}

.loading-circle:nth-child(3) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}

.loading-shadow {
  width: 20px;
  height: 4px;
  border-radius: 50%;
  // background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 62px;
  transform-origin: 50%;
  z-index: -1;
  left: 15%;
  filter: blur(1px);
  animation: shadow046 0.5s alternate infinite ease;
}

@keyframes shadow046 {
  0% {
    transform: scaleX(1.5);
  }

  40% {
    transform: scaleX(1);
    opacity: 0.7;
  }

  100% {
    transform: scaleX(0.2);
    opacity: 0.4;
  }
}

.loading-shadow:nth-child(4) {
  left: 45%;
  animation-delay: 0.2s;
}

.loading-shadow:nth-child(5) {
  left: auto;
  right: 15%;
  animation-delay: 0.3s;
}
