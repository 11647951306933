.seat-ui {
  position: relative;
  bottom: 10px;
  .seat-ui-child-1 {
    z-index: 2;
    bottom: 55.68%;
    height: 34px;
    width: 30px;
    border-bottom-left-radius: 90%50%;
    border-bottom-right-radius: 90%50%;
    border-top-left-radius: 90%50%;
    border-top-right-radius: 90%50%;
  }
  .seat-ui-child-3 {
    position: absolute;
    z-index: 1;
    bottom: 10px;
    height: 10px;
    width: 30px;
    background-color: #ffffff;
    border-bottom-left-radius: 90%60%;
    border-bottom-right-radius: 90%60%;
  }
  .seat-ui-child-2 {
    bottom: 55.68%;
    height: 14px;
    width: 30px;
    border-bottom-left-radius: 90%60%;
    border-bottom-right-radius: 90%60%;
  }
}
