::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #ccc;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

html {
  overflow: hidden;
}

body {
  overflow: hidden;
  font-size: 1rem;
}

a {
  text-decoration: none !important;
  color: unset !important;
  &:hover {
    color: unset !important;
  }
}

.pointer-cursor {
  cursor: pointer;
}

.rmdp-input {
  height: unset !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.g-cursor-pointer {
  cursor: pointer;
}
