.shimmerBG {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #ddd;
  background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
  background-size: 1200px 100%;
}

@-webkit-keyframes shimmer {
  from {
    background-position: -100% 0;
  }
  to {
    background-position: 100% 0;
  }
}
@keyframes shimmer {
  from {
    background-position: -1200px 0;
  }
  to {
    background-position: 1200px 0;
  }
}
