.driverSearchBox {
  display: none !important;
}

#showDriverSearchInput {
  display: none;
  &:checked ~ .driverSearchBox {
    display: flex !important;
  }
}

.vehicleSearchBox {
  display: none !important;
}

#showVehicleSearchInput {
  display: none;
  &:checked ~ .vehicleSearchBox {
    display: flex !important;
  }
}

.routeSearchBox {
  display: none !important;
}

#showRouteSearchInput {
  display: none;
  &:checked ~ .routeSearchBox {
    display: flex !important;
  }
}
