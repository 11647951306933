.sidePart {
  cursor: pointer;
  &:hover {
    background-color: #002649;
  }
}

.sidePartSelected {
  background-color: #002649;
}
