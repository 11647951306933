.dashboard-part {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.219) !important;
  }
}

.rmdp-input {
  width: 100% !important;
}

.dashboard-top-part {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
