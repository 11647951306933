.page-item:first-child .page-link {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.page-link.active,
.active > .page-link {
  background-color: gray !important;
  border-color: gray !important;
  color: white !important;
}

.page-link {
  color: gray !important;
}
