.routeSearchBox {
  display: none !important;
}
#showRouteSearchInput {
  display: none;
  &:checked ~ .adminSearchBox {
    display: flex !important;
  }
}

.page-item:first-child .page-link {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
